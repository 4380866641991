import {
  fetchCallMeetingDataApi,
  createMeetingBotApi,
  resumeMeetingRecordingApi,
  pauseMeetingRecordingApi,
  fetchMeetingBotStatusApi,
  removeBotFromMeetingApi
} from "@/apps/call/api"

import {
  MeetingBotStatusEnum,
  BotRecordingStatusEnum,
  BotTerminatedStatusList,
  MeetingStatusListForVideoData,
  MeetingEndedStatusList,
  MeetingDataProcessingDoneStatus,
  BotConnectedStatus
} from "@/apps/call/constants"

const getDefaultState = () => {
  return {
    isBotDisconnecting: false,
    botRecordingStatus: BotRecordingStatusEnum.NOT_RECORDING,
    meetingSummary: {},
    videoUrl: "",
    botId: "",
    meetingParticipants: {},
    speakerTimelineBlocks: {},
    meetingBotStatus: MeetingBotStatusEnum.NOT_STARTED,
    meetingTranscription: {},
    speakerTimeline: {},
    ongoingMeeting: false
  }
}

const showWarningMessage = (dispatch, title, errorMsg) => {
  dispatch("toast/setToastActiveState", true, { root: true })
  dispatch("toast/setToastType", "error", { root: true })
  dispatch(
    "toast/setToastMessage",
    { title, description: errorMsg },
    { root: true }
  )
}

const setMeetingBotStatus = (commit, meetingBotStatus) => {
  if (meetingBotStatus === MeetingDataProcessingDoneStatus) {
    commit("setMeetingBotStatus", MeetingBotStatusEnum.DONE)
  } else if (BotTerminatedStatusList.includes(meetingBotStatus)) {
    commit("setMeetingBotStatus", MeetingBotStatusEnum.ENDED)
  } else if (meetingBotStatus === BotConnectedStatus) {
    commit("setMeetingBotStatus", MeetingBotStatusEnum.CONNECTED)
  } else {
    commit("setMeetingBotStatus", MeetingBotStatusEnum.CONNECTING)
  }
}

const setMeetingParticipants = (commit, meetingParticipants) => {
  if (meetingParticipants.length) {
    const participants = []
    const participantNamesSet = new Set()
    meetingParticipants.forEach((participant) => {
      if (!participantNamesSet.has(participant.name)) {
        participants.push({ name: participant.name })
        participantNamesSet.add(participant.name)
      }
    })
    commit("setMeetingParticipants", participants)
  }
}

const setMeetingData = (commit, meetingData) => {
  // if any of bot_id or meeting_url not available then no bot is connected to the meeting yet
  if (!meetingData || !meetingData.bot_id || !meetingData.meeting_url) {
    return
  }
  // set bot id
  commit("setBotId", meetingData.bot_id)
  // set video url
  commit("setVideoUrl", meetingData.video_url)
  // set bot status
  setMeetingBotStatus(commit, meetingData.meeting_status)
  // set meeting participants
  setMeetingParticipants(commit, meetingData.participants)
  // set meeting summary
  commit("setMeetingSummary", meetingData.summary_data)
  // set meeting transcription
  commit("setMeetingTranscription", meetingData.transcription)
  // set speaker timeline
  commit("setSpeakerTimeline", meetingData.speaker_timeline)
}

const state = getDefaultState()

// getters
const getters = {
  getVideoUrl (state) {
    return state.videoUrl
  },
  getMeetingSummary (state) {
    return state.meetingSummary
  },
  getBotId (state) {
    return state.botId
  },
  getMeetingParticipants (state) {
    return state.meetingParticipants
  },
  getMeetingBotStatus (state) {
    return state.meetingBotStatus
  },
  getMeetingTranscript (state) {
    return state.meetingTranscription ? state.meetingTranscription : []
  },
  getBotRecordingStatus (state) {
    return state.botRecordingStatus
  },
  isMeetingEnded (state) {
    return MeetingEndedStatusList.includes(state.meetingBotStatus)
  },
  isMeetingDataProcessingDone (state) {
    return (
      state.meetingBotStatus === MeetingBotStatusEnum.DONE &&
      Object.keys(state.meetingSummary).length !== 0
    )
  },
  isBotConnecting (state) {
    return state.meetingBotStatus === MeetingBotStatusEnum.CONNECTING
  },
  isBotDisconnecting (state) {
    return state.isBotDisconnecting
  },
  isBotRecording (state) {
    return state.botRecordingStatus === BotRecordingStatusEnum.RECORDING
  },
  isVideoAvailable (state) {
    return MeetingStatusListForVideoData.includes(state.meetingBotStatus)
  },
  isBotActiveForMeeting (state) {
    return (
      !!state.botId &&
      [
        MeetingBotStatusEnum.CONNECTED,
        MeetingBotStatusEnum.CONNECTING
      ].includes(state.meetingBotStatus)
    )
  },
  getSpeakerTimeline (state) {
    return state.speakerTimeline
  },
  getOngoingMeeting (state) {
    return state.ongoingMeeting
  }
}

// mutations
const mutations = {
  resetCallVideoStore (state) {
    Object.assign(state, getDefaultState())
  },
  setVideoUrl (state, videoUrl) {
    state.videoUrl = videoUrl
  },
  setMeetingSummary (state, meetingSummary) {
    state.meetingSummary = meetingSummary
  },
  setBotId (state, botId) {
    state.botId = botId
  },
  setMeetingParticipants (state, meetingParticipants) {
    state.meetingParticipants = meetingParticipants
  },
  setMeetingBotStatus (state, meetingBotStatus) {
    state.meetingBotStatus = meetingBotStatus
  },
  setMeetingTranscription (state, transcription) {
    state.meetingTranscription = transcription
  },
  setIsBotDisConnecting (state, value) {
    state.isBotDisconnecting = value
  },
  setBotRecordingStatus (state, value) {
    state.botRecordingStatus = value
  },
  setSpeakerTimeline (state, speakerTimeline) {
    state.speakerTimeline = speakerTimeline
  },
  setOngoingMeeting (state, data) {
    state.ongoingMeeting = data.ongoing_meeting
  }
}

// actions
const actions = {
  setBotRecordingStatus ({ commit }, { botRecordingStatus }) {
    commit("setBotRecordingStatus", botRecordingStatus)
  },

  resetCallVideoStore ({ commit }) {
    commit("resetCallVideoStore")
  },
  async fetchCallMeetingData ({ commit }, { callId }) {
    if (!callId) {
      return
    }
    const params = { call_id: callId }
    try {
      const { data } = await fetchCallMeetingDataApi(params)
      const meetingData = data.data
      setMeetingData(commit, meetingData)
    } catch (error) {
      console.debug(error)
    }
  },
  async createMeetingBot ({ commit, dispatch }, { meetingUrl, callId }) {
    const payload = { meeting_url: meetingUrl, call_id: callId }
    commit("setMeetingBotStatus", MeetingBotStatusEnum.CONNECTING)
    try {
      const { data } = await createMeetingBotApi(payload)
      const meetingData = data.data
      commit("setBotId", meetingData.bot_id)
    } catch (error) {
      commit("resetCallVideoStore")
      showWarningMessage(
        dispatch,
        "Something went wrong",
        "Error in connecting bot to the meeting"
      )
    }
  },
  async removeBotFromMeeting ({ commit, getters, dispatch }) {
    commit("setIsBotDisConnecting", true)
    try {
      await removeBotFromMeetingApi({ bot_id: getters.getBotId })
      // reset call meeting store
      commit("resetCallVideoStore")
    } catch (error) {
      showWarningMessage(
        dispatch,
        "Something went wrong",
        "Error in removing bot from meeting"
      )
    } finally {
      commit("setIsBotDisConnecting", false)
    }
  },
  async toggleMeetingRecording ({ commit, getters, dispatch }) {
    const currentBotStatus = getters.getBotRecordingStatus
    commit("setBotRecordingStatus", BotRecordingStatusEnum.TOGGLING)
    try {
      if (currentBotStatus === BotRecordingStatusEnum.RECORDING) {
        await pauseMeetingRecordingApi({ bot_id: getters.getBotId })
        commit("setBotRecordingStatus", BotRecordingStatusEnum.NOT_RECORDING)
      } else if (currentBotStatus === BotRecordingStatusEnum.NOT_RECORDING) {
        await resumeMeetingRecordingApi({ bot_id: getters.getBotId })
        commit("setBotRecordingStatus", BotRecordingStatusEnum.RECORDING)
      }
    } catch (error) {
      commit("setBotRecordingStatus", currentBotStatus)
      showWarningMessage(
        dispatch,
        "Something went wrong",
        `Error in ${
          getters.isBotRecording ? "pausing" : "resuming"
        } meeting recording`
      )
    }
  },
  async fetchMeetingBotStatus ({ commit, getters }) {
    const params = { bot_id: getters.getBotId }
    try {
      const { data } = await fetchMeetingBotStatusApi(params)
      if (!!data && !!data.data && !!data.data.meeting_status) {
        setMeetingBotStatus(commit, data.data.meeting_status)
      }
    } catch (error) {
      console.debug(error)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
